export default [
	{
		path: 'member',
		name: 'mypage-member',
		component: () => import('@/pages/mypage/MyPageMember/MyPageMember.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
]
