export default [
	{
		path: 'market-item',
		name: 'mypage-market-item',
		component: () =>
			import(
				'@/pages/mypage/MyPageApplicationBoard/MyPageMarketItem/MyPageMarketItem.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'market-item/:itemId',
		name: 'mypage-market-item-order',
		props: true,
		component: () =>
			import(
				'@/pages/mypage/MyPageApplicationBoard/MyPageMarketItem/MyPageMarketItemOrder.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
]
