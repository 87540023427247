import Vue from 'vue'
import Vuex from 'vuex'

import { marketerRole } from '@/helpers'

Vue.use(Vuex)

const user = {
	name: 'user',
	namespaced: true,
	state: {
		me: null,
		meDetail: null,
	},
	getters: {
		getMe(state) {
			return state.me
		},
		getMeRole(state) {
			return state.meDetail?.marketerRole
		},
		getMeDetail(state) {
			return state.meDetail
		},
		isAdmin(state) {
			return state.meDetail?.marketerRole === marketerRole.ADMIN.value
		},
	},
	mutations: {
		setMe(state, data) {
			state.me = data
		},
		setMeDetail(state, data) {
			state.meDetail = data
		},
	},
	actions: {
		setMe({ commit }, data) {
			commit('setMe', data)
		},
		setMeDetail({ commit }, data) {
			commit('setMeDetail', data)
		},
	},
}

export default user
