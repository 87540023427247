export default [
	{
		path: '/course',
		name: 'course',
		component: () => import('@/pages/course/Course.vue'),
		meta: {
			layout: 'LayoutContent',
		},
	},
]
