import Vue from 'vue'

import { eventCategory } from '@/helpers'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import 'dayjs/locale/ko'

dayjs.locale('ko')
dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(isBetween)

import { postType, subjectLoungeType } from '@/helpers'

const moment = require('moment')

const NUMBER_FORMAT_STANDARD = 1000
const KR_COUNTRY_CODE = 82

export function convertUTC2KST(date) {
	return dayjs(date).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
}
Vue.filter('convertUTC2KST', convertUTC2KST)

export function timeFormat(timeStamp) {
	if (!timeStamp) {
		return ''
	}

	return dayjs(timeStamp).format('A hh:mm')
}
Vue.filter('timeFormat', timeFormat)

export function timeSiBunFormat(timeStamp) {
	if (!timeStamp) {
		return ''
	}

	return dayjs(timeStamp).format('HH시 mm분')
}
Vue.filter('timeSiBunFormat', timeSiBunFormat)

export function transactionTimeFormat(datetime) {
	if (!datetime) {
		return ''
	}

	return dayjs(datetime).format('YYYY/MM/DD HH:mm')
}
Vue.filter('transactionTimeFormat', transactionTimeFormat)

Vue.filter('dateFormat', function (datetime) {
	if (!datetime) {
		return ''
	}
	const d = datetime.split('T')
	const ymd = d[0]
	return `${ymd}`
})

Vue.filter('toCurrency', function (value) {
	if (typeof value !== 'number') {
		return value
	}
	const formatter = new Intl.NumberFormat('ko-KR', {
		minimumFractionDigits: 0,
	})
	return formatter.format(value)
})

export function dateSiFormat(datetime) {
	if (!datetime) {
		return ''
	}
	const kstDatetime = convertUTC2KST(datetime)
	return dayjs(kstDatetime).format('YYYY년 MM월 D일 A h시')
}
Vue.filter('dateSiFormat', dateSiFormat)

export function _dateFormat(datetime) {
	if (!datetime) {
		return ''
	}
	const kstDatetime = convertUTC2KST(datetime)
	return dayjs(kstDatetime).format('YYYY년 MM월 D일')
}
Vue.filter('_dateFormat', _dateFormat)

Vue.filter('formatRegBuzNo', function (value) {
	const formattedtRegBuzNo =
		value.slice(0, 3) + '-' + value.slice(3, 5) + '-' + value.slice(5, 10)
	return formattedtRegBuzNo
})

export function commaRemove(value) {
	const data =
		value.toString().indexOf(',') > -1 ? value.replace(/[^\d]/g, '') : value

	return parseInt(data)
}
Vue.filter('commaRemove', commaRemove)

export function commaFormat(value) {
	value = commaRemove(value)

	let formatValue = value
	if (!value) {
		formatValue = '0'
	} else if (value >= NUMBER_FORMAT_STANDARD) {
		const strNumber = value.toString().replace(/[^\d\\-]/g, '')
		const plainNumber = Number(strNumber).toString()
		formatValue = plainNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}
	return formatValue
}
Vue.filter('commaFormat', commaFormat)

export function internationalPhoneFormat(value) {
	let data = ''
	if (value.substring(0, 2) === '82') {
		data = value
	} else {
		data = KR_COUNTRY_CODE + value.slice(1)
	}
	return data
}
Vue.filter('internationalPhoneFormat', internationalPhoneFormat)

export function krPhoneFormat(value) {
	if (value.substring(0, 2) === '82') {
		value = '0' + value.slice(2)
	}
	return value
}
Vue.filter('krPhoneFormat', krPhoneFormat)

export function dateFormatUtcToKst(utcDatetime) {
	if (!utcDatetime) {
		return ''
	}
	const data = moment(utcDatetime, 'YYYY-MM-DDTHH:mm:ssZ')
	const kst = data['_d']
	return kst
}
Vue.filter('dateFormatUtcToKst', dateFormatUtcToKst)

export function dateUTCToKSTSibunchoFormat(datetime) {
	if (!datetime) {
		return ''
	}

	const kst = moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ')
	const result = kst['_d']
	return moment(result).format('YYYY-MM-DD HH:mm:ss')
}
Vue.filter('dateUTCToKSTSibunchoFormat', dateUTCToKSTSibunchoFormat)

export function dateDiff(t1, t2, type) {
	if (!t1 || !t2) {
		return ''
	}

	const _t1 = moment(t1, 'DD/MM/YYYY HH:mm:ss')
	const _t2 = moment(t2, 'DD/MM/YYYY HH:mm:ss')

	if (type === 'days') {
		return moment.duration(_t2.diff(_t1)).asDays()
	} else if (type === 'hours') {
		return moment.duration(_t2.diff(_t1)).asHours()
	} else if (type === 'minutes') {
		return moment.duration(_t2.diff(_t1)).asMinutes()
	} else if (type === 'milliseconds') {
		return moment.duration(_t2.diff(_t1)).asMilliseconds()
	}
}
Vue.filter('dateDiff', dateDiff)

export function dayDiff(date) {
	if (!date) {
		return
	}

	return dayjs(date).diff(dayjs(), 'day')
}
Vue.filter('dayDiff', dayDiff)

export function dateKOSimpleFormat(date) {
	if (!date) {
		return ''
	}

	return dayjs(date).format('YYYY년 MM월 D일')
}
Vue.filter('dateKOSimpleFormat', dateKOSimpleFormat)

export function dateKOFormat(date) {
	if (!date) {
		return ''
	}

	return dayjs(date).format('YYYY년 MM월 D일 A h시 m분')
}
Vue.filter('dateKOFormat', dateKOFormat)

export function dateKOSimpleDotFormat(date) {
	if (!date) {
		return ''
	}

	return dayjs(date).format('YYYY.MM.DD H:mm')
}
Vue.filter('dateKOSimpleDotFormat', dateKOSimpleDotFormat)

export function formatEventCategory(category) {
	if (category) {
		return eventCategory[category].name
	}
}
Vue.filter('formatEventCategory', formatEventCategory)

export function addDay(date, day) {
	if (!date || !day) {
		return
	}

	return dayjs(date).add(day, 'day')
}
Vue.filter('addDay', addDay)

export function addHour(date, hour, format) {
	if (!date || !hour || !format) {
		return
	}

	return moment(date).add(hour, 'hours').format(format)
}
Vue.filter('addHour', addHour)

export function removeSubStringFromString(str, subStr) {
	return str.replace(subStr, '')
}

export function convertMmtoPixel(mm) {
	return Math.round(mm / 25.4)
}

export function secondTohhmmss(seconds) {
	let sliceInit = 14
	if (!seconds) {
		return
	}

	if (seconds >= 3600) {
		sliceInit = 11
	}

	return new Date(seconds * 1000).toISOString().slice(sliceInit, 19)
}
Vue.filter('secondTohhmmss', secondTohhmmss)

export function isValidUrl(urlString) {
	const urlPattern = new RegExp(
		'^(https?:\\/\\/)?' + // validate protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
			'(\\#[-a-z\\d_]*)?$',
		'i',
	) // validate fragment locator
	return !!urlPattern.test(urlString)
}
Vue.filter('isValidUrl', isValidUrl)

export function resolvePostType(type) {
	const data = postType[type]?.name
	return data.replace(' 콘텐츠', '')
}
Vue.filter('resolvePostType', resolvePostType)

export function resoloveSubjectLoungeType(type) {
	return subjectLoungeType[type]?.name
}
Vue.filter('resoloveSubjectLoungeType', resoloveSubjectLoungeType)

export function isDateAfter(date) {
	if (!date) return false

	return dayjs().isAfter(date)
}

export function isDateBefore(date) {
	if (!date) return false

	return dayjs().isBefore(date)
}
Vue.filter('isDateBefore', isDateBefore)

export function isDateBeforeBySecond(date) {
	if (!date) return false

	return dayjs().isBefore(date, 'second')
}

export function isDateSameByDay(date) {
	if (!date) return false

	return dayjs().isSame(date, 'day')
}

export function isDateBetweenBySecond(startDate, endDate) {
	if (!startDate || !endDate) return false

	return dayjs().isBetween(startDate, endDate, 'second', '[]')
}
