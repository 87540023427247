import Vue from 'vue'
import axios from 'axios'

import AuthService from '@/services/AuthService'

const _axios = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
})

_axios.interceptors.request.use(
	config => {
		return config
	},
	error => {
		return Promise.reject(error)
	},
)

_axios.interceptors.response.use(
	response => {
		return response
	},
	async error => {
		const errorAPI = error.config
		if (error.response.data.status === 401 && errorAPI.retry === undefined) {
			errorAPI.retry = true
			await AuthService.refreshToken()
			return await _axios(errorAPI)
		}
		return Promise.reject(error)
	},
)

Vue.use({
	install(Vue) {
		window.$api = _axios
		Vue.prototype.$api = _axios
	},
})

export default _axios
