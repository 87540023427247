export default [
	{
		path: 'setting',
		name: 'mypage-setting',
		component: () => import('@/pages/mypage/MyPageSetting/MyPageSetting.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'setting/withdrawal',
		name: 'mypage-setting-withdrawal',
		component: () =>
			import('@/pages/mypage/MyPageSetting/MyPageSettingWithdrawal.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
]
