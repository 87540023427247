export default {
	namespaced: true,
	state: {
		isLogoWhite: true,
	},
	getters: {},
	mutations: {
		setIsLogoWhite(state, data) {
			state.isLogoWhite = data
		},
	},
	actions: {
		setIsLogoWhite({ commit }, data) {
			commit('setIsLogoWhite', data)
		},
	},
}
