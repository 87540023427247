import { localize } from 'vee-validate'
import ko from 'vee-validate/dist/locale/ko.json'
import i18n from '@/i18n'

localize(
	'ko',
	Object.assign(ko, {
		names: {
			id: i18n.t('validator.fields.id.name'),
			email: i18n.t('validator.fields.email.name'),
			name: i18n.t('validator.fields.name.name'),
			uid: i18n.t('validator.fields.uid.name'),
			username: i18n.t('validator.fields.username.name'),
			phone: i18n.t('validator.fields.phone.name'),
			currentPassword: i18n.t('validator.fields.currentPassword.name'),
			password: i18n.t('validator.fields.password.name'),
			repassword: i18n.t('validator.fields.repassword.name'),
			verificationCode: i18n.t('validator.fields.verificationCode.name'),
			birthDate: i18n.t('validator.fields.birthDate.name'),
			addresss: i18n.t('validator.fields.address.name'),
			title: '타이틀',
		},
		messages: {
			required: fieldName =>
				i18n.t('validator.messages.required', {
					fieldName: fieldName,
				}),
			min: (name, obj) =>
				i18n.t('validator.messages.min', {
					length: obj.length,
				}),
			max: (name, obj) =>
				i18n.t('validator.messages.max', {
					length: obj.length,
				}),
			numeric: () => i18n.t('validator.messages.numeric'),
		},
		fields: {
			email: {
				required: () => i18n.t('validator.fields.email.required'),
				email: () => i18n.t('validator.fields.email.required'),
			},
			name: {
				required: () => i18n.t('validator.fields.name.required'),
				name: () => i18n.t('validator.fields.name.required'),
			},
			gender: {
				required: () => i18n.t('validator.fields.gender.required'),
				name: () => i18n.t('validator.fields.gender.required'),
			},
			age: {
				required: () => i18n.t('validator.fields.age.required'),
				name: () => i18n.t('validator.fields.age.required'),
			},
			bloodType: {
				required: () => i18n.t('validator.fields.bloodType.required'),
				name: () => i18n.t('validator.fields.bloodType.required'),
			},
			address: {
				required: () => i18n.t('validator.fields.address.required'),
				name: () => i18n.t('validator.fields.address.required'),
			},
			hobby: {
				required: () => i18n.t('validator.fields.hobby.required'),
				name: () => i18n.t('validator.fields.hobby.required'),
			},
			birthDate: {
				required: () => i18n.t('validator.fields.birthDate.required'),
				name: () => i18n.t('validator.fields.birthDate.required'),
			},
			uid: {
				required: () => i18n.t('validator.fields.uid.required'),
				name: () => i18n.t('validator.fields.uid.required'),
			},
			username: {
				required: () => i18n.t('validator.fields.username.required'),
				name: () => i18n.t('validator.fields.username.required'),
			},
			phone: {
				required: () => i18n.t('validator.fields.phone.required'),
				phone: () => i18n.t('validator.fields.phone.required'),
			},
			password: {
				required: () => i18n.t('validator.fields.password.required'),
				min: (name, obj) =>
					i18n.t('validator.fields.password.min', {
						length: obj.length,
					}),
				max: (name, obj) =>
					i18n.t('validator.fields.password.max', {
						length: obj.length,
					}),
			},
			repassword: {
				required: () => i18n.t('validator.fields.repassword.required'),
			},
			verificationCode: {
				required: () => i18n.t('validator.fields.verificationCode.required'),
			},
		},
	}),
)
