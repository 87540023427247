const role = {
	ADMIN: { name: '관리자', value: 'ADMIN' },
	PARTNER: {
		name: '고객',
		value: 'PARTNER',
	},
	None: {
		name: '일반회원',
		value: 'None',
	},
}

export default role
