import Vue from 'vue'
import axios from 'axios'

import AuthService from '@/services/AuthService'

const _axios = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
})

_axios.interceptors.request.use(
	config => {
		const token = sessionStorage.getItem('token')
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}
		return config
	},
	error => {
		return Promise.reject(error)
	},
)

_axios.interceptors.response.use(
	response => {
		return response
	},
	async error => {
		const errorAPI = error.config
		if (error.response.status === 401 && errorAPI.retry === undefined) {
			errorAPI.retry = true
			AuthService.setSignout()
			return _axios(errorAPI)
		}
		return Promise.reject(error)
	},
)

Vue.use({
	install(Vue) {
		window.$api = _axios
		Vue.prototype.$api = _axios
	},
})

export default _axios
