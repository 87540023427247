import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const mypage = {
	name: 'mypage',
	namespaced: true,
	state: {
		answerSheet: {},
	},
	getters: {
		getAnswerSheet: state => workbookId => {
			return state.answerSheet[workbookId] || []
		},
	},
	mutations: {
		setAnswerSheet(state, payload) {
			const workbookId = payload.workbookId
			state.answerSheet[workbookId] = payload.answerSheet
		},
		emptyAnswerSheet(state) {
			state.answerSheet = {}
		},
		deleteAnswerSheet(state, payload) {
			delete state.answerSheet[payload.workbookId]
		},
	},
	actions: {
		setAnswerSheet({ commit }, payload) {
			commit('setAnswerSheet', payload)
		},
		deleteAnswerSheet({ commit }, payload) {
			commit('deleteAnswerSheet', payload)
		},
		emptyAnswerSheet({ commit }) {
			commit('emptyAnswerSheet')
		},
	},
}

export default mypage
