export default [
	{
		path: 'post-contents-me',
		name: 'mypage-post-contents-me',
		component: () =>
			import('@/pages/mypage/MyPagePostContents/MyPagePostContentsMe.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
]
