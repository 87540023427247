export default [
	{
		path: '/term/:type',
		name: 'term',
		props: true,
		component: () => import('@/pages/term/Term.vue'),
		meta: {
			layout: 'LayoutContent',
		},
		async beforeEnter(to, from, next) {
			next()
		},
	},
]
