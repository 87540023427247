const media = {
	WEB: { name: '웹', value: 'web' },
	APP: {
		name: '웹',
		value: 'app',
	},
	CHROME_EXT: { name: '크롬 익스텐션', value: 'chrome-ext' },
}

export default media
