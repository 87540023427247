export default [
	{
		path: 'market-order',
		name: 'mypage-market-order',
		component: () =>
			import(
				'@/pages/mypage/MyPageApplicationBoard/MyPageMarketOrder/MyPageMarketOrder.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'market-order/:orderId',
		name: 'mypage-market-order-detail',
		props: true,
		component: () =>
			import(
				'@/pages/mypage/MyPageApplicationBoard/MyPageMarketOrder/MyPageMarketOrder.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'market-order/:orderId/payment',
		name: 'mypage-market-order-payment',
		props: true,
		component: () =>
			import(
				'@/pages/mypage/MyPageApplicationBoard/MyPageMarketOrder/MyPageMarketOrderPayment.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'market-order/:orderId/payment-complete',
		name: 'mypage-market-order-payment-complete',
		props: true,
		component: () =>
			import(
				'@/pages/mypage/MyPageApplicationBoard/MyPageMarketOrder/MyPageMarketOrderPaymentComplete.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
]
