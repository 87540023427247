const paymentMethods = {
	CARD: {
		value: 'card',
		name: '일반 신용카드',
	},
	KAKAOPAY: {
		value: 'kakaopay',
		name: '카카오 페이',
	},
	NAVERPAY: {
		value: 'naverpay',
		name: '네이버 페이',
	},
	VBANK: {
		value: 'vbank',
		name: '가상계좌',
	},
	PAYPAL: {
		value: 'paypal',
		name: '페이팔',
	},
	// TRANS: {
	// 	value: 'trans',
	// 	name: '실시간 계좌이체',
	// },
	// PHONE: {
	// 	value: 'phone',
	// 	name: '휴대폰 소액결제',
	// },
	// CULTURELAND: {
	// 	value: 'cultureland',
	// 	name: '문화상품권',
	// },
	// SMARTCULTURE: {
	// 	value: 'smartculture',
	// 	name: '스마트문상',
	// },
	// BOOKNLIFE: {
	// 	value: 'booknlife',
	// 	name: '도서문화상품권',
	// },
}

export default paymentMethods
