const orderStatus = {
	READY: { name: '결제대기', value: 'READY' },
	PAYWAITING: { name: '입금대기', value: 'PAYWAITING' },
	PAID: { name: '결제완료', value: 'PAID' },
	REFUND_REQUESTED: { name: '환불요청', value: 'REFUND_REQUESTED' },
	REFUNDED: { name: '환불완료', value: 'REFUNDED' },
	CANCELED: { name: '취소', value: 'CANCELED' },
}

export default orderStatus
