export default [
	{
		path: '/find/email',
		name: 'find-email',
		component: () => import('@/pages/find/FindEmail.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: false,
		},
	},
	{
		path: '/find/password',
		name: 'find-password',
		component: () => import('@/pages/find/FindPassword.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: false,
		},
	},
]
