export default [
	{
		path: 'market-courses',
		name: 'mypage-market-course',
		component: () =>
			import('@/pages/mypage/MyPageMarketCourse/MyPageMarketCourse.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'market-courses/:marketCourseId/market-lectures',
		name: 'mypage-market-course-detail',
		component: () =>
			import('@/pages/mypage/MyPageMarketCourse/MyPageMarketCourseLecture.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
]
