const contentsMarketStatus = {
	READY: { name: '심사전', value: 'READY' },
	APPROVED: { name: '판매완료', value: 'APPROVED' },
	DISAPPROVED: {
		name: '반환',
		value: 'DISAPPROVED',
	},
}

export default contentsMarketStatus
