export default [
	{
		path: 'notification',
		name: 'mypage-notification',
		component: () =>
			import('@/pages/mypage/MyPageNotification/MyPageNotification.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
]
