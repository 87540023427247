import axios from '@/api/axios'

const utilityService = {
	getUserNameforbiddenWord() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/utility/constants',
				})
				resolve(data['forbiddenWord'])
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getApiServerTime() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/utility/constants',
				})
				resolve(data['now'])
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	listCountries() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/utility/countries',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default utilityService
