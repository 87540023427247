<template>
	<div style="height: 100%">
		<app-header v-if="isAppBarOpen" />
		<app-article />
	</div>
</template>
<script>
import { useRouter } from '@core/utils'
import { ref, watch } from '@vue/composition-api'

import AppHeader from '@/components/layouts/AppHeader.vue'
import AppArticle from '@/components/layouts/AppArticle.vue'

export default {
	components: {
		AppHeader,
		AppArticle,
	},
	setup() {
		const { route } = useRouter()
		const isAppBarOpen = ref(true)

		watch(
			() => route.value.query.fromApp,
			currentValue => {
				if (currentValue) {
					const fromApp = JSON.parse(currentValue)
					isAppBarOpen.value = !fromApp
				}
			},
			{
				immediate: true,
			},
		)

		return {
			isAppBarOpen,
		}
	},
}
</script>
