<template>
	<v-app class="content-layout vertical-nav">
		<app-left :is-drawer-open.sync="isDrawerOpen" v-if="isRouteMyPage" />

		<v-main tag="article">
			<router-view />
		</v-main>

		<v-speed-dial
			fixed
			right
			bottom
			style="z-index: 999"
			v-if="isRouteMyPage && !isDrawerOpen"
		>
			<template v-slot:activator>
				<v-btn fab color="primary" @click="isDrawerOpen = !isDrawerOpen">
					<v-icon>mdi-menu</v-icon>
				</v-btn>
			</template>
		</v-speed-dial>

		<alert-env />
	</v-app>
</template>
<script>
import { ref, computed } from '@vue/composition-api'

import { useRouter, getVuetify } from '@core/utils'

import AppLeft from './AppLeft'
import AlertEnv from '@/components/common/AlertEnv.vue'

export default {
	components: {
		AppLeft,
		AlertEnv,
	},
	setup() {
		const $vuetify = getVuetify()
		const { route } = useRouter()
		const isDrawerOpen = ref($vuetify.breakpoint.lgAndUp ? true : false)

		const isRouteMyPage = computed(() => {
			return route.value.matched[0].name === 'mypage'
		})

		return {
			isDrawerOpen,
			isRouteMyPage,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-progress-linear {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
}
button {
	::v-deep .v-btn__content {
		color: #ffffff;
	}
}
</style>
