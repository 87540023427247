import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth'
import user from './user'
import mypage from './mypage'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		user,
		mypage,
		app,
	},
	plugins: [
		createPersistedState({
			paths: ['mypage'],
		}),
	],
})
