export default [
	{
		path: 'license',
		name: 'mypage-license',
		component: () =>
			import(
				'@/pages/mypage/MyPageApplicationBoard/MyPageLicense/MyPageLicense.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'license-exam/:courseId',
		name: 'mypage-license-exam',
		component: () =>
			import('@/pages/mypage/MyPageLicenseExam/MyPageLicenseExam.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'license-exam-guide/:courseId',
		name: 'mypage-license-exam-guide',
		component: () =>
			import('@/pages/mypage/MyPageLicenseExam/MyPageLicenseExamGuide.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'license-exam-auth/:courseId',
		name: 'mypage-license-exam-auth',
		component: () =>
			import('@/pages/mypage/MyPageLicenseExam/MyPageLicenseExamAuth.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
]
