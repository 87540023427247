<template>
	<v-btn
		class="button kakao-login mb-3"
		large
		@click="kakaoAuth"
		:style="{ width: buttonWidth }"
	>
		<v-img
			class="mr-2"
			:src="require('@/assets/icon/kakao-icon.svg')"
			contain
			max-width="23"
			height="23"
			v-if="title === '카카오톡으로 로그인'"
		/>
		{{ title }}
	</v-btn>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
			default: '카카오톡으로 로그인',
		},
		buttonWidth: {
			type: String,
			default: '100%',
		},
		redirectUrl: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const kakaoAuth = async () => {
			// eslint-disable-next-line no-undef
			await Kakao.Auth.authorize({
				redirectUri: props.redirectUrl,
			})
		}

		return {
			kakaoAuth,
		}
	},
}
</script>
<style lang="scss" scoped>
button {
	text-transform: none !important;
	letter-spacing: normal !important;
	font-weight: 900 !important;
	font-size: 1rem;
	color: #2e2e2e;

	&.kakao-login {
		border: 0;
		background-color: #fee500 !important;
	}
}
</style>
