const applicationStatus = {
	DEFAULT: { name: '신청', value: 'DEFAULT' },
	APPLIED: { name: '신청완료', value: 'APPLIED' },
	FORCE_CHECK_IN: { name: '강제 출석체크', value: 'FORCE_CHECK_IN' },
	CANCELED: { name: '신청취소', value: 'CANCELED' },
	DONE: { name: '신청마감', value: 'DONE' },
	PAID: {
		name: '결제완료',
		value: 'PAID',
	},
}

export default applicationStatus
