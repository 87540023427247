export default [
	{
		path: '/signup',
		name: 'signup',
		component: () => import('@/pages/signup/Signup'),
		meta: {
			layout: 'LayoutContent',
		},
		async beforeEnter(to, from, next) {
			next()
		},
	},
]
