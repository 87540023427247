import axios from '@/api/axios'
import axiosWithoutHeader from '@/api/axiosWithoutHeader'
import store from '@/store'
import { media } from '@/helpers'

import UserService from '@/services/UserService'

import { auth } from '@/firebase'
import { signInWithCustomToken, signOut } from 'firebase/auth'

const authService = {
	generateAuthCodeEmail(email) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/sign-up/generate-auth-code-email',
					data: {
						email: email,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	validateAuthCodeEmail(email, code) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/sign-up/validate-auth-code-email',
					data: {
						email: email,
						code: code,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	signupItmarketerEmail(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/sign-up/it-marketer-email',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	checkEmail(email) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/check-email-availability',
					data: {
						email: email,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	checkPhoneNumber(phoneNumber) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/check-phone-number-availability',
					data: {
						phoneNumber: phoneNumber,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	checkUsername(userName) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/check-username-availability',
					data: {
						userName: userName,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	findEmailByPhoneNumber(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/find-email-by-phone-number',
					data: {
						name: payload.name,
						phoneNumber: payload.phoneNumber,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	findEmailByVerificationCode(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/find-email-by-verification-code/it-marketer',
					data: {
						name: payload.name,
						phoneNumber: payload.phoneNumber,
						verificationCode: payload.verificationCode,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	generateEmailVerificationCode() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/users/actions/generate-verification-code-email/it-marketer',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	verificationEmailCode(code) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/users/actions/verify-email',
					data: {
						verificationCode: code,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	generatePhoneNumberVerificationCode() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/users/actions/generate-verification-code-sms',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	verificationPhoneNumberCode(code) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/users/actions/verify-phone-number',
					data: {
						verificationCode: code,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	resetPassword(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/reset-password/it-marketer',
					data: {
						email: payload.email,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	changePassword(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/change-password',
					data: {
						email: payload.email,
						oldPassword: payload.oldPassword,
						newPassword: payload.newPassword,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	putUserMe(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: '/users/me',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	signin(form) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/auth/sign-in',
					data: {
						id: form.id,
						password: form.password,
						deviceName: navigator.userAgent,
						media: media.WEB.value,
					},
				})
				this.setSignin(data)
				this.signinFirebase(data.firebaseAccessToken)
				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	kakaoSignin(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/auth/sign-in/kakao',
					data: {
						code: payload.code,
						redirectUrl: payload.redirectUrl,
						deviceName: navigator.userAgent,
					},
				})
				this.setSignin(data)
				resolve(true)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	signout() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/auth/sign-out',
				})
				this.setSignout()
				signOut(auth)
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	generatePhoneNumberAuthCode(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/auth/sign-up/generate-auth-code-phone',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	validatePhoneNumberAuthCode(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/auth/sign-up/validate-auth-code-phone',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getImportUserVerification(importUid) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/auth/actions/import-user-verification/${importUid}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	validateRegNo(regNo1, regNo2, name) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/auth/validate-reg-no',
					data: {
						regNo1: regNo1,
						regNo2: regNo2,
						name: name,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	signup(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/auth/sign-up/it-marketer/with-iamport',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	signupWithEmail(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/auth/sign-up',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	inquiryReferralCode(referralCode) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `users/referral-code/${referralCode}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	deactivateUser() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/users/actions/deactivate',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getToken() {
		return {
			token: sessionStorage.getItem('token'),
		}
	},
	getFirebaseToken() {
		return {
			firebaseAccessToken: sessionStorage.getItem('firebaseAccessToken'),
		}
	},
	getFirebaseUser() {
		return {
			firebaseUser: JSON.parse(sessionStorage.getItem('firebaseUser')),
		}
	},
	updateUserCertification(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: '/users/actions/change-phone-number-with-ci',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	findEmailByCi(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/find-uid-and-email/it-marketer/with-iamport',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	resetPasswordByCi(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axiosWithoutHeader({
					method: 'POST',
					url: '/auth/actions/reset-password/it-marketer/with-iamport',
					data: payload,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	signinFirebase(firebaseAccessToken) {
		return new Promise(async (resolve, reject) => {
			try {
				const { user } = await signInWithCustomToken(auth, firebaseAccessToken)

				sessionStorage.setItem('firebaseUser', JSON.stringify(user))
				store.dispatch('auth/firebaseUser', user)
				resolve(user)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	storeSignin() {
		store.dispatch('auth/signin', this.getToken())
	},
	storeFirebaseUser() {
		const data = this.getFirebaseUser()
		store.dispatch('auth/firebaseUser', data.firebaseUser)
	},
	setSignin(data) {
		sessionStorage.setItem('token', data.token)
		sessionStorage.setItem('refreshToken', data.refreshToken)
		sessionStorage.setItem('firebaseAccessToken', data.firebaseAccessToken)

		store.dispatch('auth/signin', data)
		store.dispatch('mypage/emptyAnswerSheet')
	},
	setSignout() {
		sessionStorage.removeItem('token')
		sessionStorage.removeItem('refreshToken')
		sessionStorage.removeItem('firebaseAccessToken')

		store.dispatch('mypage/emptyAnswerSheet')
		store.dispatch('auth/signout')
	},
	async init() {
		this.storeSignin()
		this.storeFirebaseUser()
		if (store.getters['auth/isSignin'] === false) {
			return
		}
		await UserService.getMeDetail()
	},
}

export default authService
