import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
	apiKey: 'AIzaSyDqSZxJ4urZ3zL0ve7UNezjFKuFoytQ7JM',
	authDomain: 'it-marketer-38140.firebaseapp.com',
	databaseURL:
		'https://it-marketer-38140-default-rtdb.asia-southeast1.firebasedatabase.app',
	projectId: 'it-marketer-38140',
	storageBucket: 'it-marketer-38140.appspot.com',
	messagingSenderId: '1063689629477',
	appId: '1:1063689629477:web:ef3e21eeed9b1e746ff4cc',
	measurementId: 'G-H3VRNP267X',
}

initializeApp(firebaseConfig)
export const auth = getAuth()
