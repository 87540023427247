import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import i18n from '@/i18n'
import { internationalPhoneFormat } from '@/filter'

import AuthService from '@/services/AuthService'
import UtilityService from '@/services/UtilityService'

Object.keys(rules).forEach(rule => {
	extend(rule, rules[rule])
})

const isEmailUnique = async value => {
	const data = await AuthService.checkEmail(value)
	return {
		valid: !data.exist,
	}
}

extend('isEmailUnique', {
	validate: isEmailUnique,
	message: () => i18n.t('validator.rules.isEmailUnique'),
})

const isUserNameUnique = async value => {
	const data = await AuthService.checkUsername(value)
	return {
		valid: !data.exist,
	}
}

extend('isUserNameUnique', {
	validate: isUserNameUnique,
	message: () => i18n.t('validator.rules.isUserNameUnique'),
})

const isUserNameAllowable = async value => {
	const userNameForbiddenWord = await UtilityService.getUserNameforbiddenWord()
	const formatValue = value.replace(/[._-]/g, '')

	return {
		valid: !userNameForbiddenWord.some(v => formatValue.includes(v)),
	}
}

extend('isUserNameAllowable', {
	validate: isUserNameAllowable,
	message: () => i18n.t('validator.rules.isUserNameAllowable'),
})

const isPhoneNumberUnique = async value => {
	const data = await AuthService.checkPhoneNumber(
		internationalPhoneFormat(value),
	)
	return {
		valid: !data.exist,
	}
}

extend('isPhoneNumberUnique', {
	validate: isPhoneNumberUnique,
	message: () => i18n.t('validator.rules.isPhoneNumberUnique'),
})

extend('phone', {
	validate(value) {
		const regex = /^010\d{8}$/
		return regex.test(value)
	},
	message: () => i18n.t('validator.rules.phone'),
})

extend('password', {
	validate(value) {
		const regex = /^(?=.*[a-zA-Z])((?=.*[0-9])|(?=.*[!@#$%^*+=-])).{8,}$/
		return regex.test(value)
	},
	message: () => i18n.t('validator.rules.password'),
})

extend('repassword', {
	validate(value, { password }) {
		return value === password
	},
	params: ['password'],
	message: () => i18n.t('validator.rules.repassword'),
})

extend('userName', {
	validate(value) {
		const regex = /^(?=.*[a-z가-힣])[a-z가-힣_.-]*$/
		return regex.test(value)
	},
	message:
		'유저명은 한글이나 영어 소문자 필수, 특수문자는 3가지(- . _)만 허용됩니다.',
})
