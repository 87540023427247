import Vue from 'vue'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'

import './messages'
import './rules'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

configure({
	classes: {
		invalid: 'invalid',
	},
})
