<template>
	<v-app>
		<component :is="layout" :class="layout">
			<router-view />
		</component>
	</v-app>
</template>
<script>
import { computed } from '@vue/composition-api'

import { useRouter } from '@core/utils'

import LayoutContent from '@/layouts/LayoutContent.vue'
import LayoutBlank from '@/layouts/LayoutBlank.vue'

export default {
	components: {
		LayoutContent,
		LayoutBlank,
	},
	setup() {
		const { route } = useRouter()
		const layout = computed(() => route.value.meta.layout || 'layout-blank')

		if (localStorage.getItem('reloaded')) {
			localStorage.removeItem('reloaded')
		} else {
			localStorage.setItem('reloaded', '1')
			location.reload()
		}

		return {
			layout,
		}
	},
}
</script>
