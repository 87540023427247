/* eslint-disable no-async-promise-executor */
import axios from '@/api/axios'
import store from '@/store'

import UserService from '@/services/UserService'

const userService = {
	getMe() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/users/me',
				})
				store.dispatch('user/setMe', data)
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMeDetail() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/users/me/detail',
					headers: {
						'X-Issued-Licenses': true,
					},
				})
				store.dispatch('user/setMeDetail', data)
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	putMe(user) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: '/users/me',
					data: user,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	putMeRecommender(_referralCode) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'PUT',
					url: '/users/actions/change-recommender',
					data: {
						referralCode: _referralCode,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMeQrCodeUrl(userId) {
		return `${process.env.VUE_APP_API_URL}/users/qr/${userId}`
	},
	uploadProfileFile(formData) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/users/actions/upload-user-photo',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getUserRecommenderTreeMe(depth) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `users/recomender-tree/me?maxDepth=${depth}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	uploadIdPhoto(formData) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/users/actions/upload-id-photo',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	linkSigninKakao(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/users/sign-in/kakao/link',
					data: {
						code: payload.code,
						redirectUrl: payload.redirectUrl,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	unlinkSigninKakao() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: '/users/sign-in/kakao/unlink',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getParentsAndChildren() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: '/users/me/parents-and-children?orderby=id&order=asc&page=0&limit=1000',
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getUsersChuchunDetail({ page, limit }) {
		const params = {}
		if (limit) {
			params['limit'] = limit
		}
		if (page) {
			params['page'] = page
		}

		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: 'users/chuchun/detail',
					params,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getUsersSanhaDetail({ page, limit }) {
		const params = {}
		if (limit) {
			params['limit'] = limit
		}
		if (page) {
			params['page'] = page
		}

		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: 'users/sanha/detail',
					params,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	async storeUserMe() {
		const data = await UserService.getMeDetail()
		store.dispatch('user/setMe', data)
	},
	async init() {
		if (store.getters['auth/isSignin'] === false) {
			return
		}
		await this.storeUserMe()
	},
}

export default userService
