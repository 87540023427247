export default [
	{
		path: 'market-event',
		name: 'mypage-market-event',
		component: () =>
			import(
				'@/pages/mypage/MyPageApplicationBoard/MyPageMarketEvent/MyPageMarketEvent.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
]
