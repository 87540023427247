import Vue from 'vue'

import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
import VueFullPage from 'vue-fullpage.js/dist/vue-fullpage'

import App from './App'

import '@/firebase'

import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import AuthService from './services/AuthService'

import './plugins'
import './filter'
import './validator'
import './directives'

import i18n from './i18n'

Vue.config.productionTip = false

Vue.use(VueFullPage)

const timeoutInMS = 60 * 60 * 1000 // 60 minutes -> 60 * 60 * 1000
let timeoutId

const handleInactive = async () => {
	await AuthService.signout()
	router.push({ name: 'main' })
}

const startTimer = () => {
	timeoutId = setTimeout(handleInactive, timeoutInMS)
}

const resetTimer = () => {
	clearTimeout(timeoutId)
	startTimer()
}

const setupTimers = () => {
	document.addEventListener('keypress', resetTimer, false)
	document.addEventListener('mousemove', resetTimer, false)
	document.addEventListener('mousedown', resetTimer, false)
	document.addEventListener('touchmove', resetTimer, false)

	startTimer()
}

async function init() {
	try {
		window.Kakao.init(process.env.VUE_APP_KAKAOTALK_APP_KEY)
		await AuthService.init()
	} catch (e) {
		// let statusCode = e.response.status
		// if (statusCode === 401 || statusCode === 500) {
		// 	AuthService.setSignout()
		// }
		AuthService.setSignout()
		throw e
	} finally {
		new Vue({
			router,
			store,
			vuetify,
			i18n,
			render: h => h(App),
		}).$mount('#app')
	}
}

init()
setupTimers()
