export default [
	{
		path: 'application/events/:eventId',
		name: 'mypage-application-event',
		props: true,
		component: () =>
			import(
				'@/pages/mypage/MyPageApplicationBoard/MyPageApplicationBoardEventApplication.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'application/events/:eventId/payment-complete',
		name: 'mypage-application-event-payment-complete',
		props: true,
		component: () =>
			import(
				'@/pages/mypage/MyPageApplicationBoard/MyPageApplicationBoardEventPaymentComplete.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'application/events/:eventId/test',
		name: 'mypage-application-event-test',
		props: true,
		component: () =>
			import(
				'@/pages/mypage/MyPageApplicationBoard/MyPageApplicationBoardTestApplication.vue'
			),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'application-test/:workbookId',
		name: 'mypage-application-test',
		props: true,
		component: () =>
			import('@/pages/mypage/MyPageApplicationTest/MyPageApplicationTest.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
	{
		path: 'market-application',
		name: 'mypage-market-application',
		component: () =>
			import('@/pages/mypage/MyPageApplication/MyPageApplication.vue'),
		meta: {
			requiresAuth: true,
			layout: 'LayoutContent',
		},
	},
]
