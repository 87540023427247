import Swal from 'sweetalert2'

const swalMixin = Swal.mixin({
	focusConfirm: false,
	showClass: {
		popup: '',
	},
	hideClass: {
		popup: '',
	},
})

const confirmSwal = async params => {
	const {
		html = '',
		heightAuto = true,
		confirmButtonText = '확인',
		cancelButtonText = '아니오',
		allowOutsideClick = false,
	} = typeof params === 'object' ? params : { html: params }

	return await swalMixin.fire({
		html: html,
		heightAuto: heightAuto,
		showCloseButton: false,
		showCancelButton: true,
		confirmButtonText: confirmButtonText,
		confirmButtonColor: '#060539',
		cancelButtonText: cancelButtonText,
		cancelButtonColor: '#8a8d93',
		allowOutsideClick: allowOutsideClick,
	})
}

const successSwal = async params => {
	const {
		title = '',
		html = '',
		allowEscapeKey = true,
		confirmButtonText = '확인',
		allowOutsideClick = true,
	} = typeof params === 'object' ? params : { html: params }

	return await swalMixin.fire({
		title: title,
		html: html,
		icon: 'success',
		allowEscapeKey: allowEscapeKey,
		confirmButtonText: confirmButtonText,
		allowOutsideClick: allowOutsideClick,
	})
}

const warningSwal = async params => {
	const {
		html = '',
		confirmButtonText = '확인',
		allowOutsideClick = false,
	} = typeof params === 'object' ? params : { html: params }

	return await swalMixin.fire({
		html: html,
		icon: 'warning',
		confirmButtonText: confirmButtonText,
		allowOutsideClick: allowOutsideClick,
	})
}

const errorSwal = async params => {
	const {
		title = '',
		html = '',
		confirmButtonText = '확인',
	} = typeof params === 'object' ? params : { html: params }

	return await swalMixin.fire({
		title: title,
		html: html,
		icon: 'error',
		confirmButtonText: confirmButtonText,
	})
}

const infoSwal = async params => {
	const {
		title = '',
		html = '',
		showCloseButton = false,
		showCancelButton = false,
		confirmButtonText = '확인',
		cancelButtonText = '닫기',
		allowOutsideClick = false,
		reverseButtons = false,
	} = typeof params === 'object' ? params : { html: params }

	return await swalMixin.fire({
		title: title,
		html: html,
		icon: 'info',
		reverseButtons: reverseButtons,
		showCloseButton: showCloseButton,
		showCancelButton: showCancelButton,
		allowOutsideClick: allowOutsideClick,
		confirmButtonText: confirmButtonText,
		cancelButtonText: cancelButtonText,
	})
}

export { confirmSwal, successSwal, warningSwal, errorSwal, infoSwal }
