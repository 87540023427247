<template>
	<v-alert
		border="top"
		icon="mdi-information"
		colored-border
		type="error"
		v-if="isEnvLocalOrDev"
	>
		<strong>
			{{ environment }}
		</strong>
		<div>
			{{ apiUrl }}
		</div>
	</v-alert>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
	props: {},
	setup() {
		const environment = process.env.VUE_APP_NODE_ENV
		const apiUrl = process.env.VUE_APP_API_URL
		const isEnvLocalOrDev = computed(() => {
			return (
				process.env.VUE_APP_NODE_ENV === 'development' ||
				process.env.VUE_APP_NODE_ENV === 'local'
			)
		})

		return {
			apiUrl,
			environment,

			isEnvLocalOrDev,
		}
	},
}
</script>
<style lang="scss" scoped>
// Organizeing css rule order
// https://9elements.com/css-rule-order/
.v-alert {
	position: fixed;
	z-index: 99;
	bottom: 0;
	right: 0;

	margin-bottom: 0;

	font-size: 14px;
	letter-spacing: -0.6px;
}
</style>
