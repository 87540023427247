const postType = {
	GENERAL: {
		name: '일반',
		value: 'GENERAL',
	},
	CONTEST1: {
		name: '알레시',
		value: 'CONTEST1',
	},
	CONTEST2: {
		name: '라운지 콘텐츠',
		value: 'CONTEST2',
	},
	CONTEST2_DONA: {
		name: '도나 콘텐츠',
		value: 'CONTEST2_DONA',
	},
	CONTIBOOK: {
		name: '콘티북',
		value: 'CONTIBOOK',
	},
	LANDING_PAGE: {
		name: '소개페이지',
		value: 'LANDING_PAGE',
	},
	PRIVATE_INQUIRY: {
		name: '1:1문의',
		value: 'PRIVATE_INQUIRY',
	},
}

export default postType
