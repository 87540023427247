import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import { marketerRole } from '@/helpers'

Vue.use(VueRouter)

const routes = [
	require('./main'),
	require('./error'),
	require('./notice'),
	require('./auth'),
	require('./term'),
	require('./signup'),
	require('./signin'),
	require('./mypage'),
	require('./find'),
	require('./course'),
]

let mergedRoutes = []
routes.forEach(route => {
	mergedRoutes = mergedRoutes.concat(route.default)
})

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: mergedRoutes,
})

router.beforeEach(async (to, _, next) => {
	const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
	const requiresAdminRole = to.matched.some(x => x.meta.requiresAdminRole)

	if (requiresAuth && !store.getters['auth/isSignin']) {
		next(`/signin/?redirectUri=${to.fullPath}`)
	} else {
		if (
			requiresAdminRole &&
			store.getters['user/getMeRole'] !== marketerRole.ADMIN.value
		) {
			next('/mypage/dashboard')
		}

		next()
	}
})

export default router
