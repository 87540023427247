export default [
	{
		path: '/notices',
		name: 'notices',
		component: () => import('@/pages/notice/NoticeList.vue'),
		meta: {
			layout: 'LayoutContent',
		},
		async beforeEnter(to, from, next) {
			next()
		},
	},
	{
		path: '/notices/:noticeId',
		name: 'notice-detail',
		component: () => import('@/pages/notice/NoticeDetail.vue'),
		meta: {
			layout: 'LayoutContent',
		},
		props: true,
		async beforeEnter(to, from, next) {
			next()
		},
	},
]
