<template>
	<v-dialog
		v-model="isOpenSigninDialog"
		content-class="elevation-0"
		persistent
		max-width="370"
	>
		<div class="Signin mx-auto">
			<v-card elevation="4" class="d-flex flex-column mx-auto" max-width="370">
				<v-card-title class="d-flex px-0 pt-0">
					<h1 class="heading" v-html="$t('loginForm.title')"></h1>
					<v-spacer />
					<span
						@click="$emit('update:is-open-signin-dialog', false)"
						class="cursor-pointer"
					>
						<v-icon>mdi-close</v-icon>
					</span>
				</v-card-title>
				<div>
					<validation-observer ref="observer">
						<form @submit.prevent="signin">
							<validation-provider v-slot="{ errors }" name="id">
								<v-text-field
									v-model="signinForm.id"
									:error-messages="errors"
									:label="$t('loginForm.id')"
								/>
							</validation-provider>
							<validation-provider v-slot="{ errors }" name="password">
								<v-text-field
									v-model="signinForm.password"
									type="password"
									:error-messages="errors"
									:label="$t('loginForm.password')"
									@keyup="checkCapsLock"
								>
									<template v-slot:append>
										<div
											v-if="isCapsLockOn"
											class="d-flex flex-column justify-center"
											style="position: relative; bottom: 4px"
										>
											<v-icon>mdi-arrow-up-bold-box-outline</v-icon>
											<strong style="font-size: 8px">CapsLock</strong>
										</div>
									</template>
								</v-text-field>
							</validation-provider>
							<v-btn
								class="button email-login full-width mb-2"
								type="submit"
								large
								color="primary"
								:disabled="!isSubmitValid"
								:loading="signinForm.loading"
							>
								{{ $t('loginForm.submit') }}
							</v-btn>
							<kakao-signin-button :redirect-url="kakaoRedirectUrl" />
							<div class="d-flex justify-center">
								<span class="span-text" @click="routeToFindPassword">
									{{ $t('loginForm.findPassword') }}
								</span>
								<div class="vertical-line"></div>
								<span class="span-text" @click="routeToFindEmail">
									{{ $t('loginForm.findId') }}
								</span>
								<div class="vertical-line"></div>
								<span class="span-text" @click="routeToSignup">
									{{ $t('loginForm.signup') }}
								</span>
							</div>
						</form>
					</validation-observer>
				</div>
			</v-card>
		</div>
	</v-dialog>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import i18n from '@/i18n'

import { useRouter } from '@core/utils'
import { warningSwal } from '@/plugins/swalMixin'
import { internationalPhoneFormat } from '@/filter'

import AuthService from '@/services/AuthService'

import KakaoSigninButton from '@/pages/components/KakaoSigninButton.vue'

const _signinForm = {
	id: '',
	password: '',
	loading: false,
}

export default {
	components: { KakaoSigninButton },
	props: {
		isOpenSigninDialog: {
			type: Boolean,
			required: true,
		},
	},
	setup(props, { emit }) {
		const { route, router } = useRouter()
		const isCapsLockOn = ref(false)
		const kakaoRedirectUrl = ref(`${window.location.origin}/auth/kakao-signin`)
		const signinForm = ref({ ..._signinForm })

		const isSubmitValid = computed(() => {
			return (
				signinForm.value.id.length > 0 && signinForm.value.password.length > 0
			)
		})

		const formatIdPhone = id => {
			const phoneRegex = /^010\d{8}$/

			if (phoneRegex.test(id)) {
				id = internationalPhoneFormat(id)
			}

			return id
		}

		const signin = async () => {
			signinForm.value.loading = true
			const queryValue = route.value.query.redirectUri
			try {
				const data = { ...signinForm.value }

				data.id = formatIdPhone(data.id)
					.trim()
					.replace(/\t/g, '')
					.replace(/\r\n/g, '')

				await AuthService.signin(data)
				await AuthService.init()

				emit('update:is-open-signin-dialog', false)

				if (queryValue !== undefined) {
					router.push(queryValue)
				}

				router.push({ name: 'mypage-dashboard' })
			} catch (error) {
				warningSwal({
					title: i18n.t('loginForm.submitMsg.fail.title'),
					html: i18n.t('loginForm.submitMsg.fail.html'),
					confirmButtonText: i18n.t(
						'loginForm.submitMsg.fail.confirmButtonText',
					),
					allowOutsideClick: true,
				})
			} finally {
				signinForm.value = _signinForm
				signinForm.value.loading = false
			}
		}

		const routeToSignup = () => {
			emit('update:is-open-signin-dialog', false)
			router.push({ name: 'signup' })
		}

		const routeToFindPassword = () => {
			emit('update:is-open-signin-dialog', false)
			router.push({ name: 'find-password' })
		}

		const routeToFindEmail = () => {
			emit('update:is-open-signin-dialog', false)
			router.push({ name: 'find-email' })
		}

		const checkCapsLock = $event => {
			if ($event.getModifierState('CapsLock')) {
				isCapsLockOn.value = true
			} else {
				isCapsLockOn.value = false
			}
		}

		return {
			signinForm,
			isSubmitValid,
			kakaoRedirectUrl,
			isCapsLockOn,

			signin,
			checkCapsLock,
			routeToSignup,
			routeToFindPassword,
			routeToFindEmail,
		}
	},
}
</script>

<style lang="scss" scoped>
.container {
	height: 100vh;
	background-color: white;
}

.full-width {
	width: 100%;
	max-width: 100%;
}

.Signin {
	form {
		justify-content: center;
		text-align: center;

		.v-input {
			max-width: 400px;
			margin: 0 auto;
		}
	}

	.v-card {
		padding-top: 44px;
		padding-left: 34px;
		padding-right: 34px;
		padding-bottom: 34px;
		border: 1px solid #e4e5ed;
		width: 100%;

		@media screen and (max-width: 768px) {
			padding: 22px;
		}

		.signup-form {
			width: 100%;
		}
	}

	h1.heading {
		font-size: 25px;
		font-weight: normal;
		color: #303441;
		line-height: normal;
		margin-top: 0;
	}

	.span-text {
		color: #888;
		font-size: 14px !important;
		font-weight: 500 !important;
		cursor: pointer;
	}

	button {
		text-transform: none !important;
		letter-spacing: normal !important;
		font-weight: 900 !important;
		font-size: 1rem;
		color: #2e2e2e;
	}
}

.vertical-line {
	position: relative;
	top: 3px;
	height: 14px;
	width: 1px;
	margin: 0 10px;
	background-color: #dadada;
}
</style>
