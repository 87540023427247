const eventCategory = {
	EDUCATION: { name: '교육', value: 'EDUCATION' },
	EDUCATION_LICENSE: { name: '자격증 교육', value: 'EDUCATION_LICENSE' },
	LICENSE_TEST: { name: '자격증 시험', value: 'LICENSE_TEST' },
	EVENT: {
		name: '이벤트',
		value: 'EVENT',
	},
	VOLUNTEER: { name: '봉사활동', value: 'VOLUNTEER' },
}

export default eventCategory
