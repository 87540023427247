<template>
	<div>
		<v-app-bar fixed elevation="0" v-if="!isRouteMyPage">
			<div class="boxed-container w-full" :class="{ 'd-none': isCourse }">
				<div class="d-flex align-center">
					<v-toolbar-title>
						<div @click="routeToMain" class="route-to-main">
							<v-img
								:src="
									$store.state.app.isLogoWhite
										? require('@/assets/logo/logo__white.png')
										: require('@/assets/logo/logo.png')
								"
								contain
								:max-width="isSmAndDown ? 70 : 90"
								:height="isSmAndDown ? 38 : 46"
							/>
						</div>
					</v-toolbar-title>

					<v-spacer />

					<ul
						v-if="isMdAndUp"
						id="menu"
						class="d-flex justify-space-between"
						:class="$store.state.app.isLogoWhite ? 'unset' : 'set'"
					>
						<li data-menuanchor="section1" class="active">
							<a href="#section1">HOME</a>
						</li>
						<li data-menuanchor="section2">
							<a href="#section2">WE ARE</a>
						</li>
						<li data-menuanchor="section3">
							<a href="#section3">WE DO</a>
						</li>
						<li data-menuanchor="section4">
							<a href="#section4">NOTICE</a>
						</li>
					</ul>
					<v-spacer />

					<div v-if="isMdAndUp" class="d-flex">
						<template v-if="isSignin">
							<v-menu
								v-model="menu"
								:close-on-content-click="false"
								:nudge-width="140"
								left
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										class="px-0"
										:color="$store.state.app.isLogoWhite ? 'white' : 'primary'"
										text
										v-bind="attrs"
										v-on="on"
									>
										<img
											:style="{
												'color: #060539': !$store.state.app.isLogoWhite,
											}"
											:src="
												$store.state.app.isLogoWhite
													? require('@/assets/icon/login.svg')
													: require('@/assets/icon/login__primary.svg')
											"
											class="mr-1"
											width="14px"
											heigh="14px"
										/>
										<span v-if="profile && profile.uid">
											{{ profile.uid }}님
										</span>
									</v-btn>
								</template>

								<v-card class="profile-wrapper">
									<v-list>
										<v-list-item>
											<v-list-item-content>
												<v-list-item-title>
													<div class="qr-code-wrapper">
														<qrcode-vue
															:value="qrCodeUrl"
															:size="110"
															level="L"
															v-if="profile"
														/>
													</div>
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-list-item class="d-flex justify-center mt-16">
											<v-card-actions
												class="pb-1 d-flex flex-column align-center justify-center"
											>
												<v-btn
													small
													@click="routeToMyPage"
													color="primary"
													class="ml-0 mr-0 mt-0 mb-4"
												>
													{{ $t('myDialog.mypage') }}
												</v-btn>
											</v-card-actions>
										</v-list-item>
									</v-list>
									<v-divider />
									<v-card-actions class="d-flex justify-center py-2">
										<v-btn text small @click="signout">
											{{ $t('myDialog.logout') }}
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-menu>
						</template>
						<template v-else>
							<div>
								<v-btn
									:class="$store.state.app.isLogoWhite ? 'unset' : 'set'"
									small
									text
									class="text-base px-0"
									@click="isOpenSigninDialog = !isOpenSigninDialog"
								>
									{{ $t('loginTitle') }}
								</v-btn>
							</div>
						</template>
						<div
							class="vertical-line"
							:class="{ 'vertical--white': $store.state.app.isLogoWhite }"
						></div>
						<div
							class="d-flex align-center"
							:class="{ 'switcher--white': $store.state.app.isLogoWhite }"
						>
							KO
							<v-switch
								:ripple="false"
								v-model="locale"
								class="mt-0 pt-0"
								hide-details
							/>
							EN
						</div>
					</div>
					<v-app-bar-nav-icon
						v-else
						:color="$store.state.app.isLogoWhite ? 'white' : 'primary'"
						@click="isDrawerOpen = !isDrawerOpen"
					/>
				</div>
			</div>
		</v-app-bar>
		<v-navigation-drawer
			v-model="isDrawerOpen"
			disable-resize-watcher
			right
			app
		>
			<v-list class="mobile-menu">
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="d-flex align-center">
							<template v-if="isSignin">
								<img
									:style="{ 'color: #060539': !$store.state.app.isLogoWhite }"
									:src="require('@/assets/icon/login__primary.svg')"
									class="mr-1"
									width="14px"
									heigh="14px"
								/>
								<span v-if="profile && profile.uid">{{ profile.uid }}님</span>
							</template>
							<template v-else>
								<div
									class="cursor-pointer"
									@click="
										{
											isDrawerOpen = !isDrawerOpen
											isOpenSigninDialog = !isOpenSigninDialog
										}
									"
								>
									{{ $t('loginTitle') }}
								</div>
							</template>
							<div class="vertical-line"></div>
							<div class="d-flex align-center" style="max-width: 35px">
								KO
								<v-switch
									:ripple="false"
									v-model="locale"
									class="mt-0 pt-0"
									hide-details
								/>
								EN
							</div>
						</v-list-item-title>
					</v-list-item-content>
					<v-list-item-action class="ma-0">
						<v-btn small icon @click="isDrawerOpen = !isDrawerOpen">
							<v-icon small>mdi-close</v-icon>
						</v-btn>
					</v-list-item-action>
				</v-list-item>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>
							<div class="qr-code-wrapper">
								<qrcode-vue
									:value="qrCodeUrl"
									:size="100"
									level="L"
									v-if="profile"
								/>
							</div>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item href="#section1">
					<v-list-item-content>
						<v-list-item-title class="mobile-menu_title">
							HOME
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider />
				<v-list-item href="#section2">
					<v-list-item-content>
						<v-list-item-title class="mobile-menu_title">
							WE ARE
						</v-list-item-title>
					</v-list-item-content>

					<v-list-item-action>
						<v-btn icon>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</v-list-item-action>
				</v-list-item>
				<v-divider />
				<v-list-item href="#section3">
					<v-list-item-content>
						<v-list-item-title class="mobile-menu_title">
							WE DO
						</v-list-item-title>
					</v-list-item-content>

					<v-list-item-action>
						<v-btn icon>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</v-list-item-action>
				</v-list-item>
				<v-divider />
				<v-list-item href="#section4">
					<v-list-item-content>
						<v-list-item-title class="mobile-menu_title">
							NOTICE
						</v-list-item-title>
					</v-list-item-content>

					<v-list-item-action>
						<v-btn icon>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</v-list-item-action>
				</v-list-item>
				<v-divider />
				<v-list-item :to="{ name: 'mypage-dashboard' }" v-if="isSignin">
					<v-list-item-content>
						<v-list-item-title class="mobile-menu_title">
							MY PAGE
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider v-if="isSignin" />

				<div class="d-flex justify-center py-2" v-if="isSignin">
					<v-btn text @click="signout">
						{{ $t('myDialog.logout') }}
					</v-btn>
				</div>
			</v-list>
		</v-navigation-drawer>
		<signin-dialog :is-open-signin-dialog.sync="isOpenSigninDialog" />
	</div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import i18n from '@/i18n'

import store from '@/store'
import { confirmSwal } from '@/plugins/swalMixin'
import { useRouter, getVuetify } from '@core/utils'

import AuthService from '@/services/AuthService'

import QrcodeVue from 'qrcode.vue'
import SigninDialog from '@/pages/signin/SigninDialog.vue'

const KO = 'ko'
const EN = 'en'
export default {
	components: {
		SigninDialog,
		QrcodeVue,
	},
	setup() {
		const $vuetify = getVuetify()
		const { route, router } = useRouter()
		const menu = ref(false)
		const locale = ref(false)
		const isDrawerOpen = ref(false)
		const isOpenSigninDialog = ref(false)
		const profile = computed(() => store.getters['user/getMeDetail'])
		const isSignin = computed(() => store.getters['auth/isSignin'])
		const qrCodeUrl = ref('')
		const qrCodeIntervalId = ref('')
		const eventId = ref(18)

		const routeToMyPage = () => {
			menu.value = false
			router.push({ name: 'mypage-dashboard' })
		}

		const isRouteMyPage = computed(() => {
			return route.value.matched[0].name === 'mypage'
		})

		const isCourse = computed(() => {
			return route.value.matched[0].name === 'course'
		})

		const signout = async () => {
			const confirm = await confirmSwal({
				html: i18n.t('signOutForm.html'),
				confirmButtonText: i18n.t('signOutForm.confirmButtonText'),
				cancelButtonText: i18n.t('signOutForm.cancelButtonText'),
			})
			if (confirm.isConfirmed) {
				await AuthService.signout()
				if (router.currentRoute.path !== '/') {
					router.push('/')
				}
			}
		}

		const getMeQrCodeUrl = () => {
			// flutter 앱에서 qr코드 인식용
			qrCodeUrl.value = `heycandiapi://users/${profile.value?.id}?email=${
				profile.value?.email
			}&timetoken=${new Date().getTime()}`
		}

		const routeToMain = () => {
			if (route.value.name === 'main') {
				try {
					// eslint-disable-next-line no-undef
					fullpage_api.moveTo('section1')
				} catch (e) {
					console.error(e)
				}
			} else {
				router.push('/')
			}
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		watch(
			() => locale.value,
			currentValue => {
				if (currentValue) {
					i18n.locale = EN
				} else {
					i18n.locale = KO
				}
			},
		)

		watch(
			() => menu.value,
			currentValue => {
				if (currentValue) {
					if (profile.value) {
						qrCodeIntervalId.value = setInterval(getMeQrCodeUrl(), 15000)
					}
				} else {
					clearInterval(qrCodeIntervalId.value)
				}
			},
			{
				immediate: true,
			},
		)

		watch(
			() => route.value.query,
			currentValue => {
				if (
					currentValue.redirectUri &&
					currentValue.redirectUri.includes(router.options.routes[5].name)
				) {
					isOpenSigninDialog.value = true
				}
			},
			{
				immediate: true,
			},
		)

		return {
			menu,
			profile,
			locale,
			qrCodeUrl,
			eventId,
			isRouteMyPage,
			isCourse,
			isSignin,
			isOpenSigninDialog,
			isDrawerOpen,
			isMdAndUp,
			isSmAndDown,

			routeToMain,
			routeToMyPage,
			signout,
		}
	},
}
</script>
<style scoped lang="scss">
::v-deep {
	.v-navigation-drawer {
		.mobile-menu {
			.v-list-item {
				padding-left: 22px;
				padding-right: 22px;

				.v-list-item__title {
					&.mobile-menu_title {
						font-size: 22px;
						font-weight: 600;
						color: #060539;
					}
				}
			}

			.vertical-line {
				position: relative;
				top: -1px;
				height: 15px;
				width: 1px;
				margin: 0 10px;
				background-color: #060539;
			}
		}
	}

	.route-to-main {
		margin-top: 5px;
		cursor: pointer;
	}

	.profile-wrapper {
		padding-top: 60px;
	}

	.qr-code-wrapper {
		text-align: center;

		.qr-code {
			width: 127px;
			height: 127px;
		}
	}

	.route-to-answer-wrapper {
		text-align: center;
	}

	.switcher--white {
		color: white;
	}
	.v-input--selection-controls.v-input .v-input--switch__thumb {
		color: white !important;
	}
	.v-input--selection-controls.v-input .v-input--switch__track {
		color: gray !important;
	}

	.v-input--selection-controls.v-input {
		position: relative;
		left: 7px;
		top: 5px;

		.v-input--switch__track {
			width: 18px;
			height: 1.7px;
			top: calc(50% - 7px);
		}
		.v-input--switch__thumb {
			height: 7px;
			width: 7px;
		}
	}

	.vertical-line {
		width: 1px;
		position: relative;
		top: 11px;
		height: 14px;
		margin-left: 6px;
		margin-right: 20px;
		background-color: #060539;
	}
}

header {
	background-color: transparent !important;

	.vertical--white {
		color: white;
		background-color: #fff;
	}

	::v-deep {
		.v-toolbar__content {
			padding-left: 50px;
			padding-right: 50px;

			@media (max-width: 959px) {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

	.v-btn {
		&.set {
			color: #060539;
		}

		&.unset {
			color: white;
		}
	}

	#menu {
		font-size: 18px;
		font-weight: 700;
		line-height: 26px;
		padding-left: 0px;
		min-width: 389px;

		li {
			list-style-type: none;

			> a {
				text-decoration: none;
			}

			&.active > a {
				text-decoration: underline;
				text-underline-offset: 3px;
				text-decoration-thickness: 1.6px;
			}
		}

		&.set {
			li {
				color: #060539 !important;
			}
		}

		&.unset {
			li,
			li a {
				color: white !important;
			}
		}
	}
}
</style>
