const idPhotoStatus = {
	READY: { name: '대기', value: 'READY' },
	UPLOADED: { name: '승인 대기중', value: 'UPLOADED' },
	REJECTED: {
		name: '거절됨',
		value: 'REJECTED',
	},
	CONFIRMED: {
		name: '승인',
		value: 'CONFIRMED',
	},
}

export default idPhotoStatus
