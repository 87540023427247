export default [
	{
		path: '/404',
		component: () => import('@/pages/error/ErrorNotFound'),
		meta: {
			layout: 'LayoutBlank',
		},
	},
	{
		path: '*',
		redirect: '/404',
	},
]
