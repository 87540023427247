export default [
	{
		path: '/',
		name: 'main',
		component: () => import('@/pages/main/Main.vue'),
		meta: {
			layout: 'LayoutContent',
		},
		async beforeEnter(to, from, next) {
			next()
		},
	},
]
