export default [
	{
		path: 'market-balance',
		name: 'mypage-market-balance',
		component: () =>
			import('@/pages/mypage/MyPageMarketBalance/MyPageMarketBalance.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
	},
]
